.headline {
  font-size: 58px;
  line-height: em(68px, 58px);
  font-weight: normal;
  font-family: $font;

  &--def {
    color: $pr2;
    padding-bottom: 40px;
  }

  &--light {
    color: $sec2;
  }

  @media screen and (max-width: $tab) {
    font-size: 42px;
  }
}

.primaryTitle {
  font-size: 42px;
  line-height: em(52px, 42px);
  font-weight: normal;
  font-family: $font;

  &--def {
    color: $pr2;
    padding-bottom: 40px;
  }

  &--light {
    color: $sec2;
    padding-bottom: 80px;

    @media screen and (max-width: $tab) {
      padding-bottom: 40px;
    }
  }

  @media screen and (max-width: $tab) {
    font-size: 38px;
  }
}

.secondaryTitle {
  font-size: 28px;
  line-height: em(38px, 28px);
  font-weight: 300;
  font-family: $font;

  &--def {
    color: $pr2;
    padding-bottom: 40px;

    @media screen and (max-width: $tab) {
      padding-bottom: 30px;
    }
  }

  &--light {
    color: $sec2;
    padding-bottom: 40px;
  }

  @media screen and (max-width: $tab) {
    font-size: 24px;
  }
}

.bigBodyText {
  font-size: 20px;
  line-height: em(28px, 20px);
  font-weight: 300;
  font-family: $font;

  &--def {
    color: $pr2;
    padding-bottom: 40px;

    @media screen and (max-width: $tab) {
      padding-bottom: 30px;
    }
  }

  &--light {
    color: $sec2;
  }
}

.bodyText {
  font-size: 16px;
  line-height: em(24px, 16px);
  font-weight: 300;
  font-family: $font;

  &--light {
    color: $sec2;
    padding-bottom: 30px;
  }

  &--def {
    color: $pr2;
    padding-bottom: 30px;
  }
}

.smallTitle {
  font-size: 10px;
  line-height: em(15px, 10px);
  letter-spacing: em(1px, 10px);
  font-weight: 600;
  font-family: $font2;

  &--def {
    text-transform: uppercase;
    color: $pr2;
    padding-bottom: 40px;
  }

  &--light {
    text-transform: uppercase;
    color: $sec2;
    padding-bottom: 40px;
  }
}

i {
  font-style: italic;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

.title {

  &--center {
    text-align: center;
  }
}

.quotes {
  font-family: $font;
  font-size: 100px;
  line-height: 52px;
  color: $pr2;

  @media screen and (max-width: $tab) {
    padding-top: 70px;
  }
}
