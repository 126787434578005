.cookie {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: $pr1;
  padding: 26px 0px 30px;
  z-index: 5;
  transition: .4s cubic-bezier();

  @media screen and (max-width: $tab) {
    padding: 20px 20px 40px 20px;
  }

  p {
    font-size: 16px;
    line-height: em(24px, 16px);
    font-weight: 300;
    font-family: $font;
    color: $sec2;
    max-width: 80%;

    @media screen and (max-width: $tab) {
      width: 100%;
      order: 2;
      max-width: 100%;
      font-size: 14px;
    }
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  &__content {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
    }
  }

  .close {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: $tab) {
      order: 1;
      display: inline-block;
      margin: 0px 0px 16px auto;
    }

    svg {
      cursor: pointer;
      transition: .3s cubic-bezier();

      @media screen and (max-width: $tab) {
        height: 22px;
        width: 22px;
      }

      &:hover {
        transform: rotate(180deg);
      }
    }
  }

  &.closed {
    opacity: 0;
    transform: translateY(100px);
  }
}
