.linkBlock {

  &__block {
    padding-bottom: 80px;

    &:last-child {
      padding-bottom: 40px;
    }
  }

  .primaryTitle {
    text-align: center;
    padding-bottom: 80px;
  }

  &__imgWrap {
    width: 50%;
    padding-bottom: 50%;
    position: relative;

    @media screen and (max-width: $tab) {
      width: 100%;
      padding-bottom: 100%;
      order: 1;
      margin-bottom: 30px;
    }

    &:hover {

      .linkBlock__imgOverflow {
        opacity: 1;
      }
    }
  }

  &__imgOverflow {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: .2);
    opacity: 0;
    transition: .4s cubic-bezier();
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    @include bgCover();
  }

  &__content {
    width: 50%;
    padding: 0 5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: $tab) {
      width: 100%;
      order: 2;
      padding: 0px;
    }
  }

  &Link {
    font-family: $font;
    color: $sec2;
    font-size: 28px;
    font-weight: 300;
    line-height: em(38px, 28px);
    -webkit-font-smoothing: antialiased;

    @media screen and (max-width: $tab) {
      font-size: 24px;
    }

    &__icon {
      padding-right: 20px;
    }
  }
}
