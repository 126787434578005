@font-face {
  font-family: 'Domaine';
  @include fontSrc('DomaineSansText-Regular');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Domaine';
  @include fontSrc('DomaineSansText-Light');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Beatrice';
  @include fontSrc('Beatrice-Medium');
  font-weight: medium;
  font-style: normal;
}

