.footer {
  background-color: $pr1;
  padding-top: 120px;

  @media screen and (max-width: $tab) {
    padding-top: 80px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
      margin-bottom: 40px;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    @media screen and (max-width: $tab) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    &__text {
      font-family: $font2;
      font-size: 10px;
      color: $sec2;
      text-transform: uppercase;
      letter-spacing: em(1px, 10px);
      line-height: em(15px, 10px);

      @media screen and (max-width: $tab) {
        margin-bottom: 20px;
        letter-spacing: em(.5px, 10px);
        width: 100%;
      }
    }

    .address {
      text-align: right;

      @media screen and (max-width: $tab) {
        text-align: left;
      }
    }
  }

  &.hide {
    display: none;
  }

  .menu {

    @media screen and (max-width: $tab) {
      width: 100%;
    }

    .listMenu {

      @media screen and (max-width: $tab) {
        display: flex;
        flex-direction: column;
        max-height: 130px;
        flex-wrap: wrap;
        padding: 0px 17px;
      }

      &__item {

        @media screen and (max-width: $tab) {
          width: 51%;
          opacity: 1;
        }

        &:hover {

          span {

            @media screen and (max-width: $tab) {
              left: 0px;
            }
          }
        }
      }

      a {

        @media screen and (max-width: $tab) {
          font-size: 14px;
        }

        &::after {

          @media screen and (max-width: $tab) {
            content: none;
          }
        }
      }
    }
  }
}

.socials {

  @media screen and (max-width: $tab) {
    margin-bottom: 40px;
    width: 100%;
  }

  &__link {
    width: 20px;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__icon {
    fill: $sec2;
    stroke: $sec2;
    height: 20px;
    width: 20px;
  }
}

.copyright {
  padding-bottom: 50px;
  text-align: right;

  @media screen and (max-width: $tab) {
    text-align: left;
  }

  &__text {
    font-family: $font2;
    font-size: 10px;
    color: $sec2;
    letter-spacing: em(0.4px, 10px);
    line-height: em(15px, 10px);
  }

  &__logo {
    margin-left: 10px;
  }
}

.orn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 5;
  // mix-blend-mode: difference;

  path {
    fill: #EDD1BC;
  }
}

.keyIcon {

  @media screen and (max-width: $tab) {
    width: 100%;
    margin-bottom: 60px;
  }

  img {

    @media screen and (max-width: $tab) {
      width: 100%;
      height: auto;
    }
  }
}
