.furniture {

  .introBlock {
    padding-bottom: 50px;
  }

  &Content {
    padding-top: 150px;

    @media screen and (max-width: $tab) {
      padding-top: 60px;
    }
  }
}

.partnersLogo {

  &__wrap {
    margin-right: 55px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: $tab) {
      margin-right: 36px;
    }
  }

  img {
    max-width: 140px;
    height: auto;
    cursor: pointer;

    @media screen and (max-width: $tab) {
      max-width: 98px;
    }
  }
}

.titleImg {
  margin-bottom: 30px;

  img {
    max-width: 140px;
    height: auto;
  }
}
