.innerHero {
  position: fixed;
  height: 550px;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: -1;

  &__img {
    height: 100%;
    width: 100%;
    @include bgCover();
  }

  &__overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: $black;
    opacity: 0;
  }
}

.intro {

  &--hasHero {
    margin-top: 550px;
    padding-top: 0px;

    @media screen and (max-width: $tab) {
      margin-top: 270px;
    }
  }
}

.twoColGallery {
  background-color: $sec2;

  @media screen and (max-width: $tab) {
    padding: 0px 20px;
  }
}
.galleryColumn {
  width: 50%;

  @media screen and (max-width: $tab) {
    width: 100%;
    padding: 0px;
  }

  &--pdTop {
    padding-top: 100px;
  }

  &__one {
    padding-right: 50px;
    padding-left: 20px;

    @media screen and (max-width: $tab) {
      padding: 0px;
    }
    
    .galleryImgWrap {
      margin-left: auto;
      margin-right: 0px;

      img {
        margin: 0 0 0 auto;
      }

      &:nth-child(1n+1) {
        max-width: 579px;
      }

      &:nth-child(2n+2) {
        max-width: 409px;
      }

      &:nth-child(3n+3) {
        max-width: 494px;
      }

    }
  }

  &__two {
    padding-left: 50px;
    padding-right: 20px;

    @media screen and (max-width: $tab) {
      padding: 0px;
    }
  }

  .galleryImgWrap {
    margin-bottom: 150px;

    @media screen and (max-width: $tab) {
      margin-bottom: 30px;
    }

    img {
      max-width: 100%;
    }

    &:nth-child(1n+1) {
      max-width: 494px;
    }

    &:nth-child(2n+2) {
      max-width: 409px;
    }

    &:nth-child(3n+3) {
      max-width: 579px;
    }
  }

  &--negativeTop {
    position: relative;
    top: -200px;

    @media screen and (max-width: $tab) {
      top: 0px;
      margin-top: -60px;
    }
  }

  &--lessMargin {

    .galleryImgWrap {
      &:last-child {
        margin-bottom: 80px;

        @media screen and (max-width: $tab) {
          margin-bottom: 30px;
        }
      }
    }
  }
}

.techTable {
  width: 100%;

  tr {

    &:not(:last-child) {
      border-bottom: 1px solid $sec2;

      @media screen and (max-width: $tab) {
        border: none;
      }
    }

    &:last-child {

      td {

        // &:last-child {

          @media screen and (max-width: $tab) {
            border: none;
          }

        // }
      }
    }

    td {
      padding: 25px 0px 25px 40px;
      vertical-align: middle;

      @media screen and (max-width: $tab) {
        display: flex;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid $sec2;
        padding: 20px 0px 20px 20px;
      }

      &:first-child {
        border-right: 1px solid $sec2;

        @media screen and (max-width: $tab) {
          border-right: none;
        }
      }
    }
  }

  &__icon {
    display: inline-block;
    padding-right: 20px;
    opacity: 0;
    transform: translateY(40px);
    transition: 1s;

    path, rect, circle, line {
      stroke: $sec2;
    }

    &.found {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &__content {
    display: inline-block;
  }

  &__title {
    padding-bottom: 10px;
  }
}

.fullWidthImg {
  background-color: $sec2;
  width: 100%;
  padding-bottom: 150px;

  @media screen and (max-width: $tab) {
    padding-bottom: 30px;
  }

  &--lessPad {
    padding-bottom: 80px;

    @media screen and (max-width: $tab) {
      padding-bottom: 40px;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

.largeWidthImg {
  background-color: $sec2;
  padding-bottom: 150px;

  @media screen and (max-width: $tab) {
    padding: 0px 10px 80px 10px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.lessWidthImg {
  background-color: $sec2;
  padding-bottom: 150px;

  @media screen and (max-width: $tab) {
    padding-bottom: 80px;
  }

  img {
    max-width: 750px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.partWidthImg {
  background-color: $sec2;
  padding-bottom: 40px;

  .galleryImgWrap {
    width: 83%;

    @media screen and (max-width: $tab) {
      width: 100%;
    }

    &--negativeTop {
      position: relative;
      top: -150px;

      @media screen and (max-width: $tab) {
        top: 0px;
        margin-top: -60px;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.negativeMargin {
  margin-bottom: -100px;

  @media screen and (max-width: $tab) {
    margin: 0px;
  }
}

.innerText {
  background-color: $sec2;
  padding-bottom: 50px;

  @media screen and (max-width: $tab) {
    padding: 0px 20px 10px;
  }
}


