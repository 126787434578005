.input {
  background-color: transparent;
  border: none;
  border-bottom: 1.5px solid $sec2;
  min-width: 246px;
  padding: 10px 0px;
  font-family: $font;
  color: $sec2;
  font-size: 20px;
  transition: .3s cubic-bezier();

  &::placeholder {
    font-family: $font;
    color: $sec2;
    opacity: .6;
    font-size: 20px;

    @media screen and (max-width: $tab) {
      font-size: 18px;
    }
  }
}

@-webkit-keyframes autofill {
  to {
      color: $sec2;
      background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.inputs {
  position: relative;

  &.hasError {

    .input {
      border-color: $err;
    }

    .errorMsg {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

.successTitle {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.submit {
  font-family: $font2;
  font-size: 10px;
  line-height: em(15px, 10px);
  letter-spacing: em(1px, 10px);
  font-weight: 600;
  color: $sec2;
  background-color: transparent;
  padding: 13px 12px;
  width: 250px;
  border: 1.5px solid $sec2;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  transition: background-color .6s cubic-bezier();
  cursor: pointer;
  position: relative;

  @media screen and (max-width: $tab) {
    width: 100%;
  }

  &.disabled {
    pointer-events: none;
    opacity: .4;
  }

  &:hover {
    background-color: $sec2;
    color: $pr2;
  }

  &.success {
    background-color: $sec2;
    color: $sec2;

    .successTitle {
      color: $pr2;
      opacity: 1;
      width: 100%;
      transition: opacity .3s cubic-bezier();
    }
  }
}



.errorMsg {
  position: absolute;
  bottom: -20px;
  color: $err;
  opacity: 0;
  transform: translateX(30px);
  transition: .3s cubic-bezier();

  @media screen and (max-width: $tab) {
    bottom: 46px;
  }
}


.checkbox {
  font-size: 16px;
  font-family: $font;
  color: $sec2;
  max-width: 66%;
  margin: 0 auto;
  text-align: center;
  transition: .3s cubic-bezier();

  @media screen and (max-width: $tab) {
    width: 100%;
    max-width: 100%;
    text-align: left;
  }

  &.hasError {

    .checkmark {
      border: 1px solid $err;
    }
  }
}

.checkbox input {
  display: none;
}

.checkbox label {
  padding-left: 30px;
  position: relative;
  font-family: $font;
  cursor: pointer;
  display: inline-block;
}

.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  border: 1px solid $sec2;
  left: 0px;
  top: -2px;
  cursor: pointer;
  background-color: $sec2;
}

.checkmark.alrt {
  border: 1px solid $pr2;
}

.checkmark::after {
  opacity: 0;
}

.checkbox input:checked ~ .checkmark::after {
  opacity: 1;
}

.checkmark::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid $pr2;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: .2s;
}

.newsletterLink {
  position: relative;

  @media screen and (max-width: $tab) {
    text-decoration: underline;
  }

  &::after {
    content: "";
    position: absolute;
    height: 1.5px;
    width: 0%;
    bottom: -5px;
    left: 0px;
    background-color: $sec2;
    transition: .3s cubic-bezier();
  }

  &:hover {

    &::after {
      width: 100%;
    }
    
  }
}

label[for=email] {
  position: absolute;
  top: 12px;
  width: 100%;
  white-space: nowrap;
  transform-origin: left bottom;
  pointer-events: none;
  transition: .3s cubic-bezier();
  transform: scale(1) translateY(0);
  font-family: $font;
  color: $sec2;
  opacity: .6;
  font-size: 20px;

  @media screen and (max-width: $tab) {
    font-size: 18px;
  }

  &.act {
      top: -5px;
      font-size: 11px;
      opacity: 1;
      color: rgba($color: $sec2, $alpha: 1);
  }
}
