.goNext {
  height: 450px;
  background-color: $pr1;

  .flexWrap {
    height: 100%;
  }

  &__img {
    width: 60%;
    height: 100%;
    @include bgCover();

    @media screen and (max-width: $tab) {
      width: 100%;
      height: 200px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: $tab) {
      justify-content: center;
      width: 100%;
      flex-direction: row;
    }
  }

  &__link {
    margin-left: 100px;
    font-family: $font;
    color: $sec2;
    font-size: 28px;
    line-height: em(38px, 28px);
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    position: relative;
    padding-right: 70px;

    @media screen and (max-width: $tab) {
      font-size: 24px;
      margin: 0 auto;
    }
    

    &::after {
      content: "";
      position: absolute;
      height: 12px;
      width: 12px;
      border-right: 2px solid $sec2;
      border-bottom: 2px solid $sec2;
      transform: rotate(-45deg);
      right: 10px;
      top: 14px;
      transition: .3s cubic-bezier();

      @media screen and (max-width: $tab) {
        top: 12px;
      }
    }
  
    &::before {
      content: "";
      position: absolute;
      height: 2px;
      width: 50px;
      background-color: $sec2;
      right: 10px;
      top: 19px;
      transition: .3s cubic-bezier();

      @media screen and (max-width: $tab) {
        top: 17px;
      }
    }

    &:hover {

      &::after {
        right: 0px;
      }

      &::before {
        right: 0px;
      }

    }
  }

  &--extraPad {
    padding-top: 100px;

    @media screen and (max-width: $tab) {
      padding-top: 60px;
    }
  }
}
