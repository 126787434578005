.notFound {
  height: 100vh;
  width: 100vw;
  background-color: $sec2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__content {
    max-width: 474px;
    margin: 0 auto;
    text-align: center;

    @media screen and (max-width: $tab) {
      padding: 0px 20px;
    }
  }
}
