.newsletter {
  background-color: $pr1; /* Old browsers */
  background-image: -webkit-gradient( linear, left top, left bottom, color-stop( 75%, $pr1 ), color-stop( 75%, $pr1 ), color-stop( 25%, $sec2 ) ); /* Chrome, Safari4+ */
  background-image: -webkit-linear-gradient( top, $pr1 75%, $pr1 75%, $sec2 25% ); /* Chrome10+, Safari5.1+ */    
  background-image: -moz-linear-gradient( top, $pr1 75%, $pr1 75%, $sec2 25% ); /* Fx3.6+ */   
  background-image: linear-gradient( $pr1 75%, $pr1 75%, $sec2 25% );

  &--reversedBackground {
    background-color: $sec2; /* Old browsers */
    background-image: -webkit-gradient( linear, left top, left bottom, color-stop( 75%, $sec2 ), color-stop( 75%, $sec2 ), color-stop( 25%, $pr1 ) ); /* Chrome, Safari4+ */
    background-image: -webkit-linear-gradient( top, $sec2 75%, $sec2 75%, $pr1 25% ); /* Chrome10+, Safari5.1+ */    
    background-image: -moz-linear-gradient( top, $sec2 75%, $sec2 75%, $pr1 25% ); /* Fx3.6+ */   
    background-image: linear-gradient( $sec2 75%, $sec2 75%, $pr1 25% );
  }

  .container {
    @media screen and (max-width: $tab) {
      padding: 0px 10px;
    }
  }

  .colorWrap {
    background-color: $pr2;
    padding: 80px 0px;

    @media screen and (max-width: $tab) {
      padding: 60px 20px;
    }
  }

  .title {
    max-width: 494px;
    text-align: center;
    margin: 0 auto 40px;

    @media screen and (max-width: $tab) {
      padding: 0px;
    }
  }

  .inputs {
    max-width: 66%;
    margin: 0 auto 30px;

    @media screen and (max-width: $tab) {
      width: 100%;
      max-width: 100%;
    }
  }

  .input {
    width: 409px;
    margin-right: 16px;

    @media screen and (max-width: $tab) {
      margin: 0px 0px 30px 0px;
      width: 100%;
    }
  }
}

.inputWrap {

  @media screen and (max-width: $tab) {
    width: 100%;
  }
}
