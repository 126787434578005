.videoBlock {
  background-color: $sec2;
  padding-bottom: 80px;

  @media screen and (max-width: $tab) {
    padding-bottom: 40px;
  }

  .video {
    min-height: 650px;
    width: 100%;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: $tab) {
      min-height: 200px;
    }

    &__overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 3;
      @include bgCover();
      transition: .5s cubic-bezier();

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }

    video {
      position: absolute;
      transform: translate3d(-50%,-50%,0);
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .play {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);

    &__icon {
      height: 120px;
      width: 120px;
      border-radius: 50%;
      background-color: $sec2;
      position: relative;
      opacity: .8;
      transition: .3s cubic-bezier();
      cursor: pointer;
      margin: 0 auto;

      @media screen and (max-width: $tab) {
        height: 80px;
        width: 80px;
      }

      svg {
       margin-bottom: 12px;
      }

      &:hover {
        opacity: 1;
      }

      &Txt {
        font-family: $font2;
        font-size: 10px;
        text-transform: uppercase;
        color: $pr2;
        line-height: em(15px, 10px);
        letter-spacing: em(1px, 10px);
      }
    }

    &__wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    
  }

}
