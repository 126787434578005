.projectList {
  background-color: $sec2;
  padding-bottom: 100px;

  @media screen and (max-width: $tab) {
    padding-bottom: 20px;
  }

  .topNegative {
    position: relative;
    top: -50px;
  }

  .button {
    padding-left: 73px;
    padding-right: 73px;
  }
}
