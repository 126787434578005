body {
  background-color: $white;
  color: $black;
  font-family: $font;
  font-weight: 400;
  font-size: $basepx;

  &.fixed {
    height: 100%;
    overflow: hidden;
  }
}

.container {
  max-width: $md;
  width: 100%;
  margin: 0 auto;
  padding: 0px 20px;

  &--wider {
    max-width: $lg;
    width: 100%;
    margin: 0 auto;
    padding: 0px 20px;

    @media screen and (max-width: $tab) {
      padding: 0px 20px;
    }
  }

  &--fsmall {
    max-width: $fsm;
    width: 100%;
    margin: 0 auto;
  }

  &--textPage {
    max-width: $fmd;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: $tab) {
      padding: 0px 20px;
    }
  }

  @media screen and (max-width: $tab) {
    padding: 0px 20px;
  }
}

.flexWrap {
  display: flex;

  &--wrap {
    flex-wrap: wrap;
  }

  @media screen and (max-width: $tab) {
    flex-wrap: wrap;
  }
}

.iconWrap {
  margin-bottom: 40px;

  &--center {
    text-align: center;
  }
}

.section {

  &--bgBlue {
    background-color: $pr1;
  }
}

.noMob {
  @media screen and (max-width: $tab) {
    display: none;
  }
}

.onlyMob {
  @media screen and (min-width: $tab + 1px) {
    display: none;
  }
}

html.open {
  overflow: hidden;
}

