.hero {
  position: fixed;
  top: 0px;
  z-index: -1;

  .heroSwipper {
    height: 100vh;
    width: 100vw;
  }

  .slide {
    position: relative;
  }

  .videoWrap {
    height: 100vh;
    overflow: hidden;
    width: 100vw;
    display: block;
    position: relative;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%; 
    min-height: 100%; 
    width: auto; 
    height: auto;
  }

  .headlineWrap {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 660px;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .heroImg {
    height: 100%;
    width: 100%;
    @include bgCover();
  }

  .heroPagination.swiper-pagination {
    display: flex;
    justify-content: center;

    .swiper-pagination-bullet {
      height: 2px;
      width: 50px;
      background-color: #EDD1BC;
      margin: 5px;
      transition: .3s cubic-bezier();
      position: relative;
      display: block;
      border-radius: unset;
      opacity: 1;
      cursor: pointer;

      &-active, &:hover {
        width: 70px;
      }

      

      span {
        height: 100%;
        width: 0%;
        position: absolute;
        left: 0px;
        background-color: $pr1;
      }
    }
  }

  .heroPagination.swiper-pagination {
    position: absolute;
    bottom: 80px;
    z-index: 5;
    left: 50px;
    justify-content: left;
  }
}

.scrollDown {
  margin-top: calc(100vh - 40px);
  background-color: $sec2;
  height: 40px;
  position: relative;

  &__icon {
    position: absolute;
    height: 16px;
    width: 16px;
    cursor: pointer;
    margin: 12px auto;
    border-bottom: 2px solid $pr2;
    border-right: 2px solid $pr2;
    transform: rotate(45deg) translateX(-50%);
    left: 50%;
    transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      margin-top: 17px;
    }
  }
}

.about {
  background-color: $sec2;
  padding: 110px 0px 100px;

  @media screen and (max-width: $tab) {
    padding: 60px 0px 60px;
  }

  .leftBlock {
    width: 40%;
    padding-right: 8%;

    @media screen and (max-width: $tab) {
      width: 100%;
      padding-right: 0%;
    }
  }

  .rightBlock {
    width: 60%;

    @media screen and (max-width: $tab) {
      width: 100%;
    }
  }

  &__img {
    width: 100%;
    // height: 400px;
    @include bgCover();
    margin-top: 135px;

    img {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: $tab) {
      margin-top: 0px;
      margin-bottom: 30px;
    }
  }
}

.projects {
  background-color: $pr1;
  padding: 150px 0px;

  @media screen and (max-width: $tab) {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .flexWrap {
    justify-content: space-between;
  }

  .listMenu {
    padding-bottom: 40px;

    &__item.swiper-pagination-bullet {
      cursor: pointer;
      opacity: .6;
      width: unset;
      height: unset;
      display: block;
      border-radius: unset;
      background: unset;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;

      a {
        position: relative;
        
        span {
          left: 35px;
        }

        &::after {
          width: 30px;
        }
      }
    }
  }

  .leftBlock {
    padding-left: calc((100vw - 1004px) / 2);
    display: flex;
    align-items: flex-end;

    @media screen and (max-width: $tab) {
      padding: 0px 20px;
      width: 100%;
    }
  }

  .rightBlock {
    width: 65vw;
    height: 80vh;
    box-sizing: border-box;

    @media screen and (max-width: $tab) {
      width: 100%;
      height: auto;
    }
  }

  .projectsSwipper {
    height: 100%;
    width: calc(100% - 53px);
    padding-right: 53px;
    box-sizing: unset;

    .swiper-slide {
      height: 100%;
      width: 100%;
      @include bgCover();

      @media screen and (max-width: $tab) {
        height: 220px;
      }
    }
// bonus
    .swiper-wrapper {
      transition-timing-function: cubic-bezier(1, 0.3, 0.5, 1) !important;
    }

    .swiper-slide {
      transform: scale(.9);
      opacity: .7;
      transition: 1s ease-in-out;
    }
  
    .swiper-slide-active {
      transform: scale(1);
      opacity: 1;

    }
// bonus end

    @media screen and (max-width: $tab) {
      height: auto;
      padding-right: 0px;
      width: calc(100% - 40px);
      padding: 0px 20px;
    }
  }
}

.projectsControls {
  display: flex;
  position: absolute;
  bottom: 40px;
  right: 90px;
  z-index: 1;

  @media screen and (max-width: $tab) {
    position: relative;
    bottom: 0px;
    right: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  &-button-next {
    height: 16px;
    width: 70px;
    margin-left: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    svg {
      position: absolute;
      top: 0px;
      left: -20px;
      transition: .3s cubic-bezier();
    }

    &:hover {

      svg {
        left: 0px;
      }
    }
  }

  &-button-prev {
    height: 16px;
    width: 70px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    svg {
      position: absolute;
      top: 0px;
      right: -20px;
      transition: .3s cubic-bezier();
    }

    &:hover {

      svg {
        right: 0px;
      }
    }
  }

  .swiper-button-disabled {
    opacity: .6;
    pointer-events: none;
  }
}

.slogan {
  background-color: $sec2;
  padding: 115px 0px 100px;
  overflow: hidden;

  @media screen and (max-width: $tab) {
    padding-top: 0px;
    padding-bottom: 60px;
  }

  &__block {
    max-width: 100%;

    .sloganSlider {
      overflow: visible ;
    }
  }

  .swiper-slide {
    height: 300px;
    opacity: .3;
    transition: .8s cubic-bezier();

    @media screen and (max-width: $tab) {
      height: auto;
    }
  }

  .swiper-slide-active {
    opacity: 1;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;

    &-bullet {
      height: 2px;
      width: 50px;
      background: unset;
      border-radius: unset;
      background-color: #EDD1BC;
      margin: 5px;
      transition: .3s cubic-bezier();
      position: relative;
      opacity: 1;

      &-active, &:hover {
        width: 70px;
      }

      span {
        height: 100%;
        width: 0%;
        position: absolute;
        left: 0px;
        background-color: $pr1;
      }
    }
  }
}

.services {
  background-color: $pr1; /* Old browsers */
  background-image: -webkit-gradient( linear, left top, left bottom, color-stop( 33%, $sec2 ), color-stop( 67%, $sec2 ), color-stop( 67%, $pr1 ) ); /* Chrome, Safari4+ */
  background-image: -webkit-linear-gradient( top, $sec2 33%, $sec2 67%, $pr1 67% ); /* Chrome10+, Safari5.1+ */    
  background-image: -moz-linear-gradient( top, $sec2 33%, $sec2 67%, $pr1 67% ); /* Fx3.6+ */   
  background-image: linear-gradient( $sec2 33%, $sec2 67%, $pr1 67% );

  .container {
    position: relative;
  }

  .colorWrap {
    background-color: $pr2;
    padding: 100px 0px 150px;

    @media screen and (max-width: $tab) {
      margin: 0px 10px;
      padding-top: 60px;
      padding-bottom: 35px;
    }
  }

  .servicesList {

    &__item {
      width: 25%;
      text-align: center;
      padding: 25px 0px;

      @media screen and (max-width: $tab) {
        width: 80%;
        margin: 0 auto;
      }

      &:not(:last-child) {
        border-right: 1.5px solid $sec2;

        @media screen and (max-width: $tab) {
          border-right: none;
          border-bottom: 1.5px solid $sec2;
        }
      }
    }

    &__icon {
      height: 85px;
      margin-bottom: 20px;

      svg, path, line, rect, circle {
        stroke: $sec2;
      }
    }
  }
}

.tendencies {
  background-color: $pr1;
  padding: 150px 0px 100px;

  @media screen and (max-width: $tab) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .button {
    padding-left: 63px;
    padding-right: 63px;
  }
}

.insta {
  background-color: $sec2;
  padding: 150px 0px 100px;
  position: relative;

  @media screen and (max-width: $tab) {
    padding: 80px 0px 80px;
  }

  .container {
    position: relative;
  }

  &__imgWrap {
    width: 32%;
    padding-bottom: 32%;
    position: relative;
    margin-bottom: 2%;
    margin-right: 2%;
    overflow: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: 1s cubic-bezier();

    &.found {
      opacity: 1;
      transform: translateY(0px);
    }

    @media screen and (max-width: $tab) {
      width: 49%;
      padding-bottom: 49%;
    }
    
    &:nth-child(3), &:nth-child(6) {
      margin-right: 0%;

      @media screen and (max-width: $tab) {
        margin-right: 2%;
      }
    }

    &:nth-child(2), &:nth-child(4), &:nth-child(6) {

      @media screen and (max-width: $tab) {
        margin-right: 0%;
      }
    }

    &:hover {
      .insta__img {
        transform: scale(1.03);
      }

      .insta__imgOverflow {
        opacity: 1;
      }
    }
  }

  &__imgOverflow {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: .2);
    opacity: 0;
    transition: .4s cubic-bezier();
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    @include bgCover();
    transition: transform 1s ease-in-out;
  }

  &Link {
    font-family: $font;
    color: $sec2;
    font-size: 28px;
    font-weight: 300;
    line-height: em(38px, 28px);
    -webkit-font-smoothing: antialiased;

    &__icon {
      padding-right: 20px;
    }
  }

  .imgList {
    margin-bottom: 40px;
    position: relative;
  }

  .spin {
    position: absolute;
    animation-name: spin;
    animation-duration: 10000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    top: -45px;
    left: 45px;
    z-index: 2;
  }
}
