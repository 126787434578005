.accordeon {

  &__block {
    transform: translateX(-100px);
    opacity: 0;

    &:last-child {
      border-bottom: 1.5px solid rgba($color: $sec2, $alpha: .5);
    }
  }

  .head {
    border-top: 1.5px solid $sec2;
    opacity: .5;
    padding: 39px 0px 39px;
    cursor: pointer;
    transition: .3s cubic-bezier();

    &:hover {
      opacity: 1;
    }

    .flexWrap {
      align-items: center;
    }

    &__icon {
      width: 15%;

      img {
        height: 80px;
        width: auto;
        margin: 0 auto;
      }
    }

    &__content {
      width: 70%;

      @media screen and (max-width: $tab) {
        width: 75%;
      }
    }

    &__title {
      padding-bottom: 20px;

      @media screen and (max-width: $tab) {
        padding-bottom: 0px;
      }
    }

    &__subtitle {
      padding-bottom: 0px;
    }

    &__showBtnWrap {
      width: 15%;

      @media screen and (max-width: $tab) {
        width: 25%;
      }
    }

    &__showBtn {
      margin: 0 auto;
      height: 70px;
      width: 70px;
      border-radius: 50%;
      border: 1.5px solid $sec2;
      position: relative;
      cursor: pointer;
      transition: .3s cubic-bezier();

      @media screen and (max-width: $tab) {
        height: 50px;
        width: 50px;
        margin: 0 0 0 auto;
      }

      &::after {
        content: "";
        position: absolute;
        width: 1.5px;
        height: 30px;
        background-color: $sec2;
        top: 19.5px;
        left: 33px;
        transform: rotate(90deg);
        transition: .5s cubic-bezier();

        @media screen and (max-width: $tab) {
          top: 9.5px;
          left: 23px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 1.5px;
        height: 30px;
        background-color: $sec2;
        top: 19.5px;
        left: 33px;
        transform: rotate(0deg);
        transition: .5s cubic-bezier();

        @media screen and (max-width: $tab) {
          top: 9.5px;
          left: 23px;
        }
      }
    }

    &.active {
      opacity: 1;

      .head__showBtn {

        &::after {
          transform: rotate(270deg);
        }

        &::before {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .body {
    overflow: hidden;
    max-height: 0;
    transition: .4s cubic-bezier();

    .spacer {
      width: 15%;

      @media screen and (max-width: $tab) {
        width: 0%;
      }
    }

    &__content {
      width: 70%;
      padding-bottom: 60px;

      @media screen and (max-width: $tab) {
        width: 100%;
        padding-bottom: 40px;
      }
    }

    h1 {
      font-size: 58px;
      line-height: em(68px, 58px);
      font-weight: normal;
      font-family: $font;
      color: $sec2;
      padding-bottom: 40px;
    }
    
    h2 {
      font-size: 42px;
      line-height: em(52px, 42px);
      font-weight: normal;
      font-family: $font;
      color: $sec2;
      padding-bottom: 40px;
    }
    
    h3 {
      font-size: 28px;
      line-height: em(38px, 28px);
      font-weight: 300;
      font-family: $font;
      color: $sec2;
      padding-bottom: 20px;
    }
    
    h4 {
      font-size: 20px;
      line-height: em(28px, 20px);
      font-weight: 300;
      font-family: $font;
      color: $sec2;
      padding-bottom: 20px;
    }
    
    p {
      font-size: 16px;
      line-height: em(24px, 16px);
      font-weight: 300;
      font-family: $font;
      color: $sec2;
      padding-bottom: 20px;
    }
  
    ul {
  
      li {
        font-size: 16px;
        line-height: em(24px, 16px);
        font-weight: 300;
        font-family: $font;
        color: $sec2;
        padding-bottom: 20px;
        padding-left: 40px;
        position: relative;
        -webkit-font-smoothing: antialiased;
  
        &::after {
          content: "";
          position: absolute;
          top: 11px;
          left: 0px;
          width: 30px;
          height: 1.5px;
          background-color: $sec2;
        }
  
      }
    }
  
    table {
  
      thead {
  
        td {
          background-color: $sec2;
          color: $pr2;
          padding: 10px 20px;
          font-weight: 300;
          font-size: 14px;
        }
      }
  
      td {
        color: $pr2;
        padding: 20px;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;

        @media screen and (max-width: $tab) {
          display: block;
          width: 100%;
          padding: 0px;
        }
      }
    }
  }
}


.accordeon.found {

  .accordeon__block {
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-name: acco;

    &:nth-child(1) {
      animation-delay: .25s;
    }

    &:nth-child(2) {
      animation-delay: .5s;
    }

    &:nth-child(3) {
      animation-delay: .75s;
    }

    &:nth-child(4) {
      animation-delay: 1s;
    }

    &:nth-child(5) {
      animation-delay: 1.25s;
    }

    &:nth-child(6) {
      animation-delay: 1.5s;
    }

    &:nth-child(7) {
      animation-delay: 1.75s;
    }

    &:nth-child(8) {
      animation-delay: 2s;
    }
    
  }
}

@include keyframes(acco) {
  from {
    // opacity: 0;
    // transform: translateY(-100);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
