@include keyframes(rotation) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: rotation;
}

.imgAnimate {
  transform: translateY(40%) scale(0.85);
  opacity: 0;
  transition: 2s cubic-bezier();

  &.found {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
}

.textAnimate {
  transform: translateY(10%);
  opacity: 0;
  transition: 1s cubic-bezier();
  transition-delay: .5s;

  &.found {
    opacity: 1;
    transform: translateY(0%);
  }
}

.servicesList__item {
  transform: translateY(5%);
  opacity: 0;
  transition: 1s cubic-bezier();

  &.found {
    opacity: 1;
    transform: translateY(0%);
  }
}

.hasTail {
  position: relative;

  img {
    position: relative;
    z-index: 1;
    transition: 2s cubic-bezier();
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba($color: $pr2, $alpha: .08);
    z-index: 0;
    transition: 1s cubic-bezier();
  }

  &__up {

    & > img {
      transform: translate(0%, -7%);
      transition: 1s cubic-bezier();
    }

    &::after {
      // transform: translateY(-40px);
      transform: translate(-2%, -9%);
      transition: .8s cubic-bezier();
    }
  }

  &__down {

    & > img {
      transform: translate(0%, 7%);
      transition: 1s cubic-bezier();
    }

    &::after {
      // transform: translateY(40px);
      transform: translate(-2%, 9%);
      transition: .8s cubic-bezier();
    }
  }
}


@include keyframes(slide) {
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(-30px);
  }
}

.scrollIcon__anim {
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-name: slide;
  animation-iteration-count: infinite;
}
