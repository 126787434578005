.listMenu {

  &__item {
    margin-bottom: 14px;
    transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    a {
      font-family: $font;
      font-weight: 300;
      font-size: 16px;
      line-height: em(24px, 16px);
      -webkit-font-smoothing: antialiased;
      transition: .3s cubic-bezier();
      position: relative;
      

      &::after {
        content: "";
        position: absolute;
        height: 1.5px;
        width: 0px;
        top: 9px;
        left: 0px;
        background-color: $sec2;
        transition: .3s cubic-bezier();
      }

      span {
        position: relative;
        transition: .3s cubic-bezier();
        left: 0px;
      }
    }

    &--light {
      color: $sec2;
      opacity: .6;
    }

    &:hover {
      opacity: 1;

      a {
        position: relative;
        
        span {
          left: 35px;
        }

        &::after {
          width: 30px;
        }
      }
    }
  }
}
