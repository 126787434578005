.textPage {
  padding-bottom: 150px;

  @media screen and (max-width: $tab) {
    padding-bottom: 80px;
  }

  &__intro {
    padding-bottom: 100px;

    @media screen and (max-width: $tab) {
      padding-bottom: 60px;
    }
    

    .introBlock {
      padding-bottom: 80px;

      @media screen and (max-width: $tab) {
        padding-bottom: 20px;
      }
    }
  }

  h1 {
    font-size: 58px;
    line-height: em(68px, 58px);
    font-weight: normal;
    font-family: $font;
    color: $pr2;
    padding-bottom: 40px;
  }
  
  h2 {
    font-size: 42px;
    line-height: em(52px, 42px);
    font-weight: normal;
    font-family: $font;
    color: $pr2;
    padding-bottom: 40px;
  }
  
  h3 {
    font-size: 28px;
    line-height: em(38px, 28px);
    font-weight: 300;
    font-family: $font;
    color: $pr2;
    padding-bottom: 20px;

    @media screen and (max-width: $tab) {
      font-size: 24px;
    }
  }
  
  h4 {
    font-size: 20px;
    line-height: em(28px, 20px);
    font-weight: 300;
    font-family: $font;
    color: $pr2;
    padding-bottom: 20px;
  }
  
  p {
    font-size: 16px;
    line-height: em(24px, 16px);
    font-weight: 300;
    font-family: $font;
    color: $pr2;
    padding-bottom: 20px;

    @media screen and (max-width: $tab) {
      font-size: 14px;
    }
  }

  ul {

    li {
      font-size: 16px;
      line-height: em(24px, 16px);
      font-weight: 300;
      font-family: $font;
      color: $pr2;
      padding-bottom: 20px;
      padding-left: 40px;
      position: relative;
      -webkit-font-smoothing: antialiased;

      @media screen and (max-width: $tab) {
        font-size: 14px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 11px;
        left: 0px;
        width: 30px;
        height: 1.5px;
        background-color: $pr2;

        @media screen and (max-width: $tab) {
          top: 9px;
        }
      }

    }
  }

  table {

    thead {

      td {
        background-color: $pr2;
        color: $sec2;
        padding: 10px 20px;
        font-weight: 300;
        font-size: 14px;
      }
    }

    td {
      border: 1px solid $pr2;
      color: $pr2;
      padding: 20px;
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.tableOverflowWrap {
  overflow-x: scroll;
  width: calc(100% + 20px);
}

.scrollIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  font-size: 10px;
  color: $pr2;
  text-transform: uppercase;

  &__anim {
    background-image: url('../../assets/images/ic_hand.png');
    @include bgContain();
    height: 30px;
    width: 20px;
    margin-right: 15px;
  }

}
