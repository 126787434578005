.btnWrap {

  &--center {
    text-align: center;
  }
}

.button {
  display: inline-block;
  font-size: 10px;
  line-height: em(16px, 10px);
  letter-spacing: em(1px, 10px);
  font-family: $font2;
  border: 1.5px solid black;
  padding: 13px 35px 12px 26px;
  text-transform: uppercase;
  transition: .6s cubic-bezier();

  @media screen and (max-width: $tab) {
    width: 100%;
    text-align: center;
    padding: 11px 35px 10px 26px;
  }


  &--pr {
    color: $pr2;
    border-color: $pr2;

    &:hover {
      color: $sec1;
      background-color: $pr2;
    }
  }

  &--light {
    color: $sec2;
    border-color: $sec2;

    &:hover {
      color: $pr2;
      background-color: $sec2;
    }
  }
}

.arrowBtn {
  display: block;
  width: 50px;
  height: 16px;
  position: relative;
  transition: .3s cubic-bezier();

  &::after {
    content: "";
    position: absolute;
    height: 12px;
    width: 12px;
    border-right: 2px solid $sec2;
    border-bottom: 2px solid $sec2;
    transform: rotate(-45deg);
    right: 0px;
    top: 2px;
  }

  &::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: $sec2;
    left: 0px;
    top: 7px;
  }

  &:hover {
    width: 70px;
  }

  &--darker {

    &::after {
      border-color: $pr2;
    }

    &::before {
      background-color: $pr2;
    }

  }
}
