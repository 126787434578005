.intro {
  padding-top: 140px;
  background-color: $sec2;

  @media screen and (max-width: $tab) {
    padding-top: 87px;
  }

  &Block {
    max-width: 66%;
    padding: 80px 11% 130px 0%;
    position: relative;
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-name: introLoad;

    @media screen and (max-width: $tab) {
      max-width: calc(100% - 10px);
      padding: 60px 20px 60px 20px;
    }

    &--right {
      margin-left: 34%;
      padding-left: 85px;
      padding-right: 0px;
      position: relative;
      top: -100px;
      animation-duration: 1.2s;
      animation-fill-mode: both;
      animation-name: introLoadRight;

      @media screen and (max-width: $tab) {
        margin-left: 10px;
        padding-left: 20px;
        padding-right: 20px;
        top: -30px;
      }

      .introBlock__contentWidth {
        margin: 0 auto 0 0;
      }
    }

    &__contentWidth {
      max-width: $md / 1.5 - 20px;
      margin: 0 0 0 auto;
      padding: 0px 20px;
    }

    &--brown {
      background-color: $pr2;
    }

    &--blue {
      background-color: $pr1;
    }
    
  }
}

.animatedIntroContent {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: introContLoad;

  &:nth-child(1) {
    animation-delay: 1.2s;
  }

  &:nth-child(2) {
    animation-delay: 1.5s;
  }

  &:nth-child(3) {
    animation-delay: 1.8s;
  }

  &:nth-child(4) {
    animation-delay: 2.1s;
  }

  &:nth-child(5) {
    animation-delay: 2.4s;
  }
}

@include keyframes(introLoad) {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@include keyframes(introLoadRight) {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@include keyframes(introContLoad) {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
