$xl: 1440px;
$lg: 1240px;
$md: 1044px;
$fmd: 834px;
$tab: 768px;
$fsm: 750px;
$sm: 540px;
$basepx: 14px;

$font: 'Domaine', sans-serif;
$font2: 'Beatrice', sans-serif;

$white:  #FFF;
$black:  #000;
$pr1: #031B3F;
$pr2: #A8866F;
$sec1: #EDD1BC;
$sec2: #FFEDE1;
$err: #FF806F;

