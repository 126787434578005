.about {

  &Intro {

    .introBlock {
      padding-bottom: 100px;

      &__widthWrap {
        max-width: 429px;
        padding-left: 20px;
      }
    }
  }

  &Video {
    background-color: $sec2; /* Old browsers */
    background-image: -webkit-gradient( linear, left top, left bottom, color-stop( 75%, $sec2 ), color-stop( 75%, $sec2 ), color-stop( 25%, $pr1 ) ); /* Chrome, Safari4+ */
    background-image: -webkit-linear-gradient( top, $sec2 75%, $sec2 75%, $pr1 25% ); /* Chrome10+, Safari5.1+ */    
    background-image: -moz-linear-gradient( top, $sec2 75%, $sec2 75%, $pr1 25% ); /* Fx3.6+ */   
    background-image: linear-gradient( $sec2 75%, $sec2 75%, $pr1 25% );
  }

  &Accordeon {
    background-color: $pr1;
  }
}

.team {
  background-color: $sec2;

  &__block {
    padding-bottom: 60px;
  }

  &__content {
    max-width: 70%;

    @media screen and (max-width: $tab) {
      max-width: 100%;
      width: 100%;
    }
  }

  &__title {
    padding-bottom: 10px;
  }

  &__subtitle {
    padding-bottom: 20px;
  }

  &__text {
    padding-bottom: 20px;
  }

  &__quote {
    padding-top: 20px;

    @media screen and (max-width: $tab) {
      padding-top: 0px;
      margin-bottom: 80px;
    }
  }
}

.teamColumn {
  width: 50%;

  @media screen and (max-width: $tab) {
    width: 100%;
  }

  &--pdTop {
    padding-top: 100px;
  }

  &__one {
    padding-right: 40px;

    @media screen and (max-width: $tab) {
      padding: 0px 20px;
      margin-top: -30px;
      position: relative;
    }

    .team__block {

      &:nth-child(odd) {
        padding-left: 80px;

        @media screen and (max-width: $tab) {
          padding: 0px 0px 40px;
        }

        img {
          max-height: 540px;
          width: auto;
        }
      }

      &:nth-child(even) {

        @media screen and (max-width: $tab) {
          padding: 0px 0px 40px;
        }

        img {
          max-height: 650px;
          width: auto;
        }
      }
    }


    
    .teamImgWrap {

      img {
        width: 100%;
        height: auto;
      }

    }
  }

  &__two {
    padding-left: 40px;

    @media screen and (max-width: $tab) {
      padding: 0px 20px;
    }

    .team__block {

      &:nth-child(even) {

        img {
          max-height: 540px;
          width: auto;
        }
      }

      &:nth-child(odd) {

        img {
          max-height: 650px;
          width: auto;
        }
      }

      &:nth-child(2) {
        
        @media screen and (max-width: $tab) {
          padding-bottom: 0px;
        }
      }
    }
  }

  .teamImgWrap {
    margin-bottom: 20px;

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  &--negativeTop {
    position: relative;
    margin-top: -450px;

    @media screen and (max-width: $tab) {
      margin: 0px;
    }
  }

  &--lessMargin {

    .teamImgWrap {
      &:last-child {
        margin-bottom: 80px;
      }
    }
  }
}
