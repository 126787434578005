.header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 5;
  background-color: transparent;
  height: 140px;
  transition: .3s cubic-bezier();

  @media screen and (max-width: $tab) {
    height: 70px;
  }
  

  &Width {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 20px;

    @media screen and (max-width: $tab) {
      padding: 0px 20px;
    }
  }

  .logo {

    svg {
      height: 60px;
      width: auto;
      transition: .3s cubic-bezier();

      @media screen and (max-width: $tab) {
        height: 44px;
      }
    }
  }
}

.navigation {
  
  &__list {
    display: flex;
  }

  &__item {
    color: $sec2;
    font-family: $font2;
    font-size: 10px;
    letter-spacing: em(1px, 10px);
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    position: relative;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &::after {
      content: "";
      position: absolute;
      height: 1.5px;
      width: 0%;
      bottom: -5px;
      left: 0px;
      background-color: $sec2;
      transition: width .3s cubic-bezier();
    }

    &--active, &:hover {

      &::after {
        width: 100%;
      }
    }

    
  }

  &__lang {
    color: $sec2;
    font-family: $font2;
    font-size: 10px;
    letter-spacing: em(1px, 10px);
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
  }

  &__hasSub {
    padding-right: 15px;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      border-right: 1.5px solid $sec2;
      border-bottom: 1.5px solid $sec2;
      transform: rotate(45deg);
      height: 7px;
      width: 7px;
      top: -1px;
      right: 0px;
      transition: .3s cubic-bezier();
    }

    &:hover {

      &::after {
        top: 2px;
        transform: rotate(225deg);
      }

      .submenu {
        opacity: 1;
        pointer-events: all;
        transform: translate(-50%, 0px);
      }


    }
  }

  .submenu {
    opacity: 0;
    pointer-events: none;
    display: block;
    position: absolute;
    color: $pr2;
    left: 50%;
    transform: translate(-50%, 20px);
    top: 10px;
    padding-top: 11px;
    transition: .3s cubic-bezier();

    .triangle {
      position: relative;
      width: 100%;

      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent #ffede1 transparent;
        top: -4px;
        left: 33px;
      }
    }

    li {
      background-color: $sec2;
      width: 100%;
      padding: 8px 25px 5px 10px;
      position: relative;

      &:not(:last-child) {

        &::after {
          content: "";
          position: absolute;
          height: 1.5px;
          width: 34px;
          background-color: $pr2;
          bottom: 0px;
          left: 5px;
        }
        
      }
    }
    
  }
}

#header.shrink {
  height: 80px;
  background-color: rgba($color: $sec2, $alpha: .9);

  @media screen and (max-width: $tab) {
    height: 70px;
  }

  .navigation__item{
    color: $pr2;

    &::after {
      background-color: $pr2;
    }
  }

  .navigation__lang  {
    color: $pr2;
  }

  .logo {

    svg {
      height: 50px;
      width: auto;

      @media screen and (max-width: $tab) {
        height: 44px;
      }
    }

    path {
      fill: $pr2;
    }
  }

  .navigation__hasSub {

    &::after {
      border-color: $pr2;
    }
  }

  .icon {

    &__line {
      background-color: $pr2;
    }
  }
}

#header.inner:not(.shrink) {

  .navigation__item{
    color: $pr2;

    &::after {
      background-color: $pr2;
    }
  }

  .navigation__lang  {
    color: $pr2;
  }

  .logo {

    path {
      fill: $pr2;
    }
  }

  .navigation__hasSub {

    &::after {
      border-color: $pr2;
    }
  }

  .icon__line {
    background-color: $pr2;
  }
}

#header.inner.heroHasImg:not(.shrink) {

  .navigation__item{
    color: $sec2;

    &::after {
      background-color: $sec2;
    }
  }

  .navigation__lang  {
    color: $sec2;
  }

  .logo {

    path {
      fill: $sec2;
    }
  }

  .navigation__hasSub {

    &::after {
      border-color: $sec2;
    }
  }

  .icon__line {
    background-color: $sec2;
  }
}

.mMenuToggler {
  z-index: 10;
  position: absolute;
  top: 23px;
  right: 23px;

  .icon {
    height: 30px;
    width: 30px;

    &__line {
      width: 100%;
      height: 0.15em;
      background-color: $sec2;
      transition: .3s cubic-bezier(0.39, 0.575, 0.565, 1);

      &--upper {
        margin-bottom: 8px;
        margin-top: 5px;
        position: relative;
        top: 0px;
      }
    }
  }

  &.open {

    .icon__line {

      &--upper {
        transform: rotate(45deg);
        top: 10px;
      }

      &--lower {
        transform: rotate(-45deg);
      }
    }
  }
}

.mobileMenu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: $pr1;
  padding: 23px 0px 0px 0px;
  transform: translateX(100%);
  transition: .3s cubic-bezier(0.39, 0.575, 0.565, 1);
  overflow-y: scroll;

  &.open {
    transform: translateX(0%);
  }

  &__lang {
    color: $white;
    display: inline-block;
    padding: 0px 15px 0px 40px;
    margin-bottom: 80px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      border-right: 1.5px solid $white;
      border-bottom: 1.5px solid $white;
      transform: rotate(45deg);
      height: 7px;
      width: 7px;
      top: 1px;
      right: 0px;
      transition: .3s cubic-bezier();
    }

    &Submenu {
      position: absolute;
      pointer-events: none;
      opacity: 0;
      transform: translateY(20px);
      transition: .3s cubic-bezier();

      li {
        margin: 10px 0px;
      }
    }

    &:hover {

      &::after {
        transform: rotate(225deg);
        top: 5px
      }

      .mobileMenu__langSubmenu {
        opacity:  1;
        transform: translateY(0px);
        pointer-events: all;
      }

    }
  }

  &__listMenu {
    padding: 0px 0px 0px 40px;

    .listMenu__item {
      margin-bottom: 30px;

      & a {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }

  &__bottom {
    margin-top: 100px;
    position: relative;
    bottom: 0px;
    width: 100%;
  }

  &__bottomWrap {
    align-items: center;
    justify-content: center;
  }
}

