.contacts {
  min-height: 100vh;
  width: 100vw;
  background-color: $sec2;
  position: relative;

  @media screen and (max-width: $tab) {
    height: auto;
    position: relative;
  }

  &__cont.container {
    z-index: 1;
    position: relative;
    padding: 200px 0px 200px;

    @media screen and (max-width: $tab) {
      top: 0px;
      transform: translateY(0px);
    }
  }

  &__mapBlock {
    width: 60%;

    @media screen and (max-width: $tab) {
      width: 100%;
      order: 2;
    }
  }

  &__mapWrap {
    height: 400px;
    width: 100%;

    @media screen and (max-width: $tab) {
      height: 350px;
    }

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  &__textBlock {
    width: 40%;
    padding-left: 100px;
    padding-top: 15px;

    @media screen and (max-width: $tab) {
      width: 100%;
      order: 1;
      padding-left: 0px;
      padding-bottom: 50px;
      padding-top: 107px;
    }
  }
}

.contactSingleBlock {

  &__icon {
    padding-bottom: 20px;
  }

  &__title {
    padding-bottom: 20px;
  }

  &__link {
    display: block;
    padding-bottom: 5px;
    -webkit-font-smoothing: antialiased;
  }

  &:not(:last-child) {
    padding-bottom: 30px;

    @media screen and (max-width: $tab) {
      padding-bottom: 0px;
    }
  }
}

.contactsSocials {
  margin-top: 40px;

  @media screen and (max-width: $tab) {
    padding-bottom: 38px;
  }

  &__wrap {
    height: 65px;
    align-items: center;
  }

  &__link {
    margin-left: 40px;
  }

  &__spin {
    animation-name: spin;
    animation-duration: 10000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(-360deg);
  }
}

.halfBackground {
  z-index: 0;
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: $pr1;
  top: 0px;
  left: 0px;

  @media screen and (max-width: $tab) {
    width: 100%;
    height: 40%;
    top: auto;
    bottom: 0px;
  }
} 
